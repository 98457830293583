// colors
$white: #fff;
$black:#333;
$blue-main: #0098D5;
$blue-light: #00C9FF;
$blue-xlight: #EBFBFF;
$grade-green: #92fe9d;
$grade-blue: #00c9ff;
$shadow: #2773AE;
$border: #DBDCDD;
$border-blue: #D9EBF3;
$gray: #888;
$orange: #FF3D00;
$error-text: #e80303;
$error-bg: #fce3e2;


// fonts
.mincho {
  font-family: "Noto Serif JP", serif;
}
.en, .num, .date {
  font-family: "Open Sans", sans-serif;
}

// gradation
.grade {
  background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}

// cubic-bezier
@mixin cubic-bezier {
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
