@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// contact
.contact-lead {
  margin-bottom: 50px;
  @include m.mq(max599) {
    margin-bottom: 30px;
  }
}
.contact-table {
  width: 100%;
  margin-top: -25px;
  @include m.mq(max599) {
    margin-top: -15px;
  }
  th,td {
    letter-spacing: 0.04em;
    @include m.mq(max1023) {
      display: block;
    }
    @include m.mq(max599) {
      font-size: 14px;
    }
  }
  th {
    width: 300px;
    padding: 26px 25px 25px 0;
    font-weight: 700;
    vertical-align: top;
    @include m.mq(max1023) {
      width: 100%;
      padding: 25px 0 0;
    }
    @include m.mq(max599) {
      padding: 15px 0 0;
    }
    .text {
      display: inline-block;
      margin-top: 2px;
    }
    .required {
      float: right;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 30px;
      padding: 0px 4px;
      border-radius: 15px;
      background-color: v.$orange;
      color: v.$white;
      font-size: 0.8em;
      font-weight: 700;
      letter-spacing: 0.08em;
      @include m.mq(max599) {
        width: 50px;
        height: 24px;
      }
    }
  }
  td {
    padding: 15px 0;
    @include m.mq(max599) {
      padding: 10px 0 5px;
    }
    .confirm-data {
      display: block;
      height: auto;
      min-height: 45px;
      padding: 14px 20px;
      border-radius: 4px;
      background-color: v.$blue-xlight;
    }
    input,
    textarea {
      padding: 15px 15px;
      border-radius: 4px;
      background-color: v.$blue-xlight;
      font-size: 16px;
      letter-spacing: 0.04em;
      @include m.mq(max599) {
        padding: 12px 15px;
      }
      &.full {
        width: 100%;
      }
    }

    // エラー文
    input,textarea {
      &.error {
        border: 1px solid v.$error-text;
        background-color: v.$error-bg;
      }
    }
    label.error {
      display: block;
      position: relative;
      margin-top: 0.5em;
      color: v.$error-text;
      font-size: 0.9em;
    }
  }
}
.contact-privacy {
  margin-top: 30px;
}
.contact-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 60px;
  @include m.mq(max767) {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 40px;
  }
  .btn.contact-btn {
    margin: 0;
  }
}
.contact-error-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
  color: v.$error-text;
  @include m.mq(max599) {
    font-size: 14px;
  }
}
