@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// inner
.inner {
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: content-box;
	@include m.mq(max599) {
		padding:  0 20px;
	}
}

// text
.md {
	font-size: 16px;
	letter-spacing: 0.08em;
	text-align: justify;
	line-height: 2.5;
	@include m.mq(max599) {
		font-size: 14px;
	}
}
.text {
	@include m.mq(max599) {
		font-size: 14px;
	}
}
.date {
	flex-shrink: 0;
  font-weight: 600;
  letter-spacing: 0.08em;
	@include m.mq(max599) {
		font-size: 14px;
	}
}

// text-align
.center {
	text-align: center;
}
.right {
	text-align: right;
}
.left {
	text-align: left;
}

// box-shadow
.box-shadow {
	box-shadow: 0 0 40px 0 color-mix(in srgb, v.$shadow 16%, transparent);
}

// position
.relative {
	position: relative;
}

// display
.ib {
	display: inline-block;
}

// pointer-events
.inactive {
	pointer-events: none;
}

// header
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}
.header-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin: 0 auto;
	padding: 20px 30px;
	@include m.mq(max1200) {
		width: auto;
	}
	@include m.mq(max599) {
		padding: 15px 20px 20px 10px;
	}
}
.header-logo {
	position: relative;
	z-index: 100;
	@include m.mq(max599) {
		width: 100px;
	}
}
.header-nav {
	display: flex;
	align-items: center;
	gap: 30px;
	@include m.mq(max1023) {
		overflow: scroll;
		opacity: 0;
		visibility: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 30;
		padding: 120px 40px 60px;
		box-shadow: 0 2px 4px 0 color-mix(in srgb, v.$black 15%, transparent);
		background-color: v.$blue-xlight;
		@include v.cubic-bezier;
	}
	&.active {
		opacity: 1;
		visibility: visible;
		@include v.cubic-bezier;
	}
}
@media screen and (max-height: 480px) {
	.header-nav {
		bottom: 0;
	}
}
.header-nav-list {
	display: flex;
	gap: 40px;
	letter-spacing: 0.08em;
	@include m.mq(max1023) {
		flex-direction: column;
		align-items: center;
	}
}
.header-contact-btn {
	display: inline-flex;
	justify-content: center;
	width: 120px;
	height: 44px;
	padding: 10px;
	border-radius: 22px;
}

// footer
.footer {
	position: relative;
	margin-top: 300px;
	padding: 100px 0 30px;
	background-color: #D3F6FF;
	@include m.mq(max599) {
		margin-top: 260px;
		padding: 20px 0 30px;
	}
}
.footer-main {
	display: flex;
	justify-content: space-between;
	gap: 100px;
	@include m.mq(max599) {
		flex-direction: column;
		gap: 60px;
	}
}
.footer-left {
	flex-shrink: 0;
}
.footer-logo {
	@include m.mq(max599) {
		width: 100px;
	}
}
.footer-title {
	margin-top: 16px;
	font-size: 17px;
	font-weight: 500;
	letter-spacing: 0.08em;
	@include m.mq(max599) {
		margin-top: 12px;
		font-size: 15px;
	}
}
.footer-info {
	margin-top: 16px;
	font-size: 16px;
	letter-spacing: 0.04em;
	line-height: 1.8;
	@include m.mq(max599) {
		margin-top: 10px;
		font-size: 14px;
	}
}
.footer-nav {
	width: 500px;
	@include m.mq(max599) {
		width: auto;
	}
}
.footer-nav-list {
	display: flex;
	flex-wrap: wrap;
	gap: 30px 40px;
	@include m.mq(max599) {
		gap: 24px 30px;
	}
	li {
		width: 140px;
		border-top: 1px solid v.$blue-main;
		letter-spacing: 0.08em;
		@include m.mq(max599) {
			width: calc((100% - 30px)/2);
		}
		a {
			display: flex;
			flex-direction: column;
			gap: 4px;
			padding-top: 12px;
			.en {
				font-size: 20px;
				@include m.mq(max599) {
					font-size: 16px;
				}
			}
			.jp {
				font-size: 12px;
				@include m.mq(max599) {
					font-size: 10px;
				}
			}
		}
	}
}
.footer-bottom {
	margin-top: 80px;
	font-size: 12px;
	letter-spacing: 0.08em;
	@include m.mq(max599) {
		font-size: 10px;
	}
	&, a {
		color: v.$gray;
	}
	small {
		display: block;
		margin-top: 16px;
	}
}


// section
section {
	padding-top: 240px;
	@include m.mq(max599) {
		padding-top: 120px;
	}
}


// section-title
.section-title {
	margin-bottom: 80px;
	line-height: 1;
	@include m.mq(max599) {
		margin-bottom: 48px;
	}
	.en {
		margin-bottom: 0.3em;
		font-size: 80px;
		letter-spacing: 0.04em;
		@include m.mq(max599) {
			font-size: 40px;
		}
		.head {
			color: v.$blue-light;
		}
	}
	.jp {
		font-size: 14px;
		letter-spacing: 0.1em;
		@include m.mq(max599) {
			font-size: 12px;
		}
	}
}

.lower-section-title {
	margin-bottom: 40px;
	font-size: 36px;
	font-weight: 500;
	letter-spacing: 0.08em;
	@include m.mq(max599) {
		margin-bottom: 30px;
		font-size: 24px;
	}
}

