@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// 下層KV
.lower-kv {
  padding-top: 200px;
  @include m.mq(max599) {
    padding-top: 140px;
  }
}
.lower-kv-title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0.12em;
  @include m.mq(max599) {
    font-size: 28px;
  }
}

// 下層共通
.page-body {
  margin: 100px 0;
  @include m.mq(max599) {
    margin: 60px 0 0;
  }
}
