@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// link
a, button {
  transition: 0.3s;
  @include m.hover() {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.btn {
  @include m.hover() {
    .arrow {
      right: 20px;
      transition: 0.3s;
    }
  }
}

// wave
.wave {
  position: absolute;
  top: -190px;
  width: 100%;
  height: 200px;
}
#waveCanvas {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
}

// 流体シェイプ
.fluid-about {
  position: absolute;
  top: -350px;
  left: -700px;
  z-index: -1;
  svg {
    width: 1400px;
    height: 1200px;
  }
  @include m.mq(max599) {
    top: -180px;
    left: -240px;
    svg {
      width: 600px;
      height: 500px;
    }
  }
}
.fluid-service {
  position: absolute;
  top: 200px;
  right: -700px;
  z-index: -1;
  svg {
    width: 1400px;
    height: 1200px;
  }
  @include m.mq(max599) {
    top: -130px;
    right: -240px;
    svg {
      width: 600px;
      height: 500px;
    }
  }
}
.fluid-news {
  position: absolute;
  top: -200px;
  left: -250px;
  z-index: -1;
  svg {
    width: 500px;
    height: 500px;
  }
  @include m.mq(max599) {
    top: -150px;
    left: -180px;
  }
}
.fluid-company01 {
  position: absolute;
  top: 0;
  right: -300px;
  z-index: -1;
  svg {
    width: 500px;
    height: 500px;
  }
  @include m.mq(max599) {
    top: -100px;
    right: -200px;
  }
}
.fluid-company02 {
  position: absolute;
  bottom: -200px;
  left: -300px;
  z-index: -1;
  svg {
    width: 500px;
    height: 500px;
  }
}

// GSAP
.fadeInUp {
  opacity: 0;
}

