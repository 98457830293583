@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// ボタン
.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  letter-spacing: 0.08em;
  &.common-btn {
    max-width: 320px;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: v.$blue-main;
    color: v.$white;
    @include m.mq(max599) {
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      padding-right: 20px;
    }
  }
  &.contact-btn {
    max-width: 400px;
    width: 100%;
    height: 60px;
    margin: 40px auto 0;
    border-radius: 30px;
  }
  &.margin-auto {
    margin: 50px auto 0;
    @include m.mq(max599) {
      margin: 40px auto 0;
    }
  }
  &.back {
    background-color: v.$gray;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: v.$white;
    transition: 0.3s;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
button {
  color: v.$black;
}

// リンク
.link-text,
.article a {
  color: v.$blue-main;
  text-decoration: underline;
  @include m.hover() {
    text-decoration: none;
  }
}
.tel-link {
  pointer-events: none;
  @include m.mq(max599) {
    pointer-events: auto;
  }
}

// ヘッダーメニュー
.header-menu-btn {
	display: none;
	@include m.mq(max1023) {
		display: block;
		position: relative;
		z-index: 100;
		width: 50px;
		height: 50px;
		border-radius: 4px;
	}
}
.header-menu-btn-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 24px;
	height: 16px;
	span {
		display: block;
		position: absolute;
		width: 100%;
		height: 2px;
    border-radius: 1px;
		background-color: v.$black;
		@include v.cubic-bezier;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		&:nth-child(3) {
			bottom: 0;
		}
	}
}
.active .header-menu-btn-inner {
	span {
		@include v.cubic-bezier;
		&:nth-child(1) {
			top: 50%;
			transform: translateY(-50%);
		}
		&:nth-child(3) {
			bottom: 50%;
			transform: translateY(50%);
		}
	}
}
.header-layer {
	opacity: 0;
	visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background-color: color-mix(in srgb, v.$black 60%, transparent);
	transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
	&.active {
		@include m.mq(max1023) {
			opacity: 1;
			visibility: visible;
			@include v.cubic-bezier;
		}
	}
}

// パンくずリスト
.breadcrumb {
  margin-top: 30px;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.04em;
	@include m.mq(max599) {
    margin-top: 15px;
		font-size: 11px;
	}
  ul {
    display: flex;
		flex-wrap: wrap;
    gap: 0px 36px;
		@include m.mq(max599) {
			gap: 0px 24px;
		}
		li {
			position: relative;
			&:after {
				content:"›";
				position: absolute;
        top: 1px;
        right: -21px;
				font-size: 15px;
        line-height: 1;
				@include m.mq(max599) {
          top: -1px;
					right: -15px;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
      a {
        transition: 0.3s;
        @include m.hover() {
          opacity: 0.7;
          transition: 0.3s;
        }
      }
		}
  }
}

// 記事
.article {
  letter-spacing: 0.08em;
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.7em;
    font-weight: 700;
    letter-spacing: 0.1em;
  }
  h1,h2 {
    padding: 0.5em 1em;
    border-left: 5px solid v.$blue-main;
    background-color: v.$blue-xlight;
    font-size: 28px;
    @include m.mq(max599) {
      font-size: 20px;
    }
  }
  h3 {
    padding-bottom: 0.25em;
    border-bottom: 1px solid v.$black;
    font-size: 24px;
    @include m.mq(max599) {
      font-size: 18px;
    }
  }
  h4 {
    font-size: 20px;
    @include m.mq(max599) {
      font-size: 16px;
    }
  }
  h5,h6 {
    font-size: 18px;
    @include m.mq(max767) {
      font-size: 15px;
    }
  }
  p, ul {
    margin-bottom: 40px;
    line-height: 2;
    @include m.mq(max599) {
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}

// プライバシーポリシー（個人情報の取り扱いに関する弊社窓口）
.privacy-window {
  margin-top: 80px;
  padding: 40px;
  background-color: v.$blue-xlight;
  letter-spacing: 0.08em;
  @include m.mq(max599) {
    margin-top: 60px;
    padding: 30px 20px;
  }
  h3 {
    margin-bottom: 0.7em;
    font-size: 20px;
    font-weight: 700;
    @include m.mq(max599) {
      font-size: 16px;
    }
  }
  p {
    line-height: 2;
    @include m.mq(max599) {
      font-size: 14px;
    }
  }
}