@charset "UTF-8";
.mincho {
  font-family: "Noto Serif JP", serif;
}

.en, .num, .date {
  font-family: "Open Sans", sans-serif;
}

.grade {
  background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  -webkit-text-size-adjust: none;
  overflow-y: scroll;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #333;
  line-height: 1.5;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.is-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

#wrapper {
  overflow: hidden;
}

a {
  color: #333;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ol {
  padding-left: 20px;
  list-style-type: decimal;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.btn.common-btn {
  max-width: 320px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #0098D5;
  color: #fff;
}
@media screen and (max-width: 599px) {
  .btn.common-btn {
    margin-left: auto;
    margin-right: auto;
  }
}
.btn.common-btn .text {
  padding-right: 20px;
}
.btn.contact-btn {
  max-width: 400px;
  width: 100%;
  height: 60px;
  margin: 40px auto 0;
  border-radius: 30px;
}
.btn.margin-auto {
  margin: 50px auto 0;
}
@media screen and (max-width: 599px) {
  .btn.margin-auto {
    margin: 40px auto 0;
  }
}
.btn.back {
  background-color: #888;
}
.btn .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.3s;
}
.btn .arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  color: #333;
}

.link-text,
.article a {
  color: #0098D5;
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .link-text:hover,
.article a:hover {
    text-decoration: none;
  }
}

.tel-link {
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .tel-link {
    pointer-events: auto;
  }
}

.header-menu-btn {
  display: none;
}
@media screen and (max-width: 1023px) {
  .header-menu-btn {
    display: block;
    position: relative;
    z-index: 100;
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
}

.header-menu-btn-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 16px;
}
.header-menu-btn-inner span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #333;
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
.header-menu-btn-inner span:nth-child(1) {
  top: 0;
}
.header-menu-btn-inner span:nth-child(2) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.header-menu-btn-inner span:nth-child(3) {
  bottom: 0;
}

.active .header-menu-btn-inner span {
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
.active .header-menu-btn-inner span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%);
}
.active .header-menu-btn-inner span:nth-child(3) {
  bottom: 50%;
  transform: translateY(50%);
}

.header-layer {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background-color: color-mix(in srgb, #333 60%, transparent);
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
@media screen and (max-width: 1023px) {
  .header-layer.active {
    opacity: 1;
    visibility: visible;
    transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  }
}

.breadcrumb {
  margin-top: 30px;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .breadcrumb {
    margin-top: 15px;
    font-size: 11px;
  }
}
.breadcrumb ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 36px;
}
@media screen and (max-width: 599px) {
  .breadcrumb ul {
    gap: 0px 24px;
  }
}
.breadcrumb ul li {
  position: relative;
}
.breadcrumb ul li:after {
  content: "›";
  position: absolute;
  top: 1px;
  right: -21px;
  font-size: 15px;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .breadcrumb ul li:after {
    top: -1px;
    right: -15px;
  }
}
.breadcrumb ul li:last-child:after {
  display: none;
}
.breadcrumb ul li a {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .breadcrumb ul li a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.article {
  letter-spacing: 0.08em;
}
.article h1, .article h2, .article h3, .article h4, .article h5, .article h6 {
  margin-bottom: 0.7em;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.article h1, .article h2 {
  padding: 0.5em 1em;
  border-left: 5px solid #0098D5;
  background-color: #EBFBFF;
  font-size: 28px;
}
@media screen and (max-width: 599px) {
  .article h1, .article h2 {
    font-size: 20px;
  }
}
.article h3 {
  padding-bottom: 0.25em;
  border-bottom: 1px solid #333;
  font-size: 24px;
}
@media screen and (max-width: 599px) {
  .article h3 {
    font-size: 18px;
  }
}
.article h4 {
  font-size: 20px;
}
@media screen and (max-width: 599px) {
  .article h4 {
    font-size: 16px;
  }
}
.article h5, .article h6 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .article h5, .article h6 {
    font-size: 15px;
  }
}
.article p, .article ul {
  margin-bottom: 40px;
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .article p, .article ul {
    margin-bottom: 30px;
    font-size: 14px;
  }
}

.privacy-window {
  margin-top: 80px;
  padding: 40px;
  background-color: #EBFBFF;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .privacy-window {
    margin-top: 60px;
    padding: 30px 20px;
  }
}
.privacy-window h3 {
  margin-bottom: 0.7em;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .privacy-window h3 {
    font-size: 16px;
  }
}
.privacy-window p {
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .privacy-window p {
    font-size: 14px;
  }
}

.inner {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: content-box;
}
@media screen and (max-width: 599px) {
  .inner {
    padding: 0 20px;
  }
}

.md {
  font-size: 16px;
  letter-spacing: 0.08em;
  text-align: justify;
  line-height: 2.5;
}
@media screen and (max-width: 599px) {
  .md {
    font-size: 14px;
  }
}

@media screen and (max-width: 599px) {
  .text {
    font-size: 14px;
  }
}

.date {
  flex-shrink: 0;
  font-weight: 600;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .date {
    font-size: 14px;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.box-shadow {
  box-shadow: 0 0 40px 0 color-mix(in srgb, #2773AE 16%, transparent);
}

.relative {
  position: relative;
}

.ib {
  display: inline-block;
}

.inactive {
  pointer-events: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px 30px;
}
@media screen and (max-width: 1200px) {
  .header-inner {
    width: auto;
  }
}
@media screen and (max-width: 599px) {
  .header-inner {
    padding: 15px 20px 20px 10px;
  }
}

.header-logo {
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 599px) {
  .header-logo {
    width: 100px;
  }
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 1023px) {
  .header-nav {
    overflow: scroll;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 30;
    padding: 120px 40px 60px;
    box-shadow: 0 2px 4px 0 color-mix(in srgb, #333 15%, transparent);
    background-color: #EBFBFF;
    transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  }
}
.header-nav.active {
  opacity: 1;
  visibility: visible;
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}

@media screen and (max-height: 480px) {
  .header-nav {
    bottom: 0;
  }
}
.header-nav-list {
  display: flex;
  gap: 40px;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 1023px) {
  .header-nav-list {
    flex-direction: column;
    align-items: center;
  }
}

.header-contact-btn {
  display: inline-flex;
  justify-content: center;
  width: 120px;
  height: 44px;
  padding: 10px;
  border-radius: 22px;
}

.footer {
  position: relative;
  margin-top: 300px;
  padding: 100px 0 30px;
  background-color: #D3F6FF;
}
@media screen and (max-width: 599px) {
  .footer {
    margin-top: 260px;
    padding: 20px 0 30px;
  }
}

.footer-main {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
@media screen and (max-width: 599px) {
  .footer-main {
    flex-direction: column;
    gap: 60px;
  }
}

.footer-left {
  flex-shrink: 0;
}

@media screen and (max-width: 599px) {
  .footer-logo {
    width: 100px;
  }
}

.footer-title {
  margin-top: 16px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .footer-title {
    margin-top: 12px;
    font-size: 15px;
  }
}

.footer-info {
  margin-top: 16px;
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 1.8;
}
@media screen and (max-width: 599px) {
  .footer-info {
    margin-top: 10px;
    font-size: 14px;
  }
}

.footer-nav {
  width: 500px;
}
@media screen and (max-width: 599px) {
  .footer-nav {
    width: auto;
  }
}

.footer-nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 40px;
}
@media screen and (max-width: 599px) {
  .footer-nav-list {
    gap: 24px 30px;
  }
}
.footer-nav-list li {
  width: 140px;
  border-top: 1px solid #0098D5;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .footer-nav-list li {
    width: calc((100% - 30px) / 2);
  }
}
.footer-nav-list li a {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 12px;
}
.footer-nav-list li a .en {
  font-size: 20px;
}
@media screen and (max-width: 599px) {
  .footer-nav-list li a .en {
    font-size: 16px;
  }
}
.footer-nav-list li a .jp {
  font-size: 12px;
}
@media screen and (max-width: 599px) {
  .footer-nav-list li a .jp {
    font-size: 10px;
  }
}

.footer-bottom {
  margin-top: 80px;
  font-size: 12px;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .footer-bottom {
    font-size: 10px;
  }
}
.footer-bottom, .footer-bottom a {
  color: #888;
}
.footer-bottom small {
  display: block;
  margin-top: 16px;
}

section {
  padding-top: 240px;
}
@media screen and (max-width: 599px) {
  section {
    padding-top: 120px;
  }
}

.section-title {
  margin-bottom: 80px;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .section-title {
    margin-bottom: 48px;
  }
}
.section-title .en {
  margin-bottom: 0.3em;
  font-size: 80px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .section-title .en {
    font-size: 40px;
  }
}
.section-title .en .head {
  color: #00C9FF;
}
.section-title .jp {
  font-size: 14px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .section-title .jp {
    font-size: 12px;
  }
}

.lower-section-title {
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .lower-section-title {
    margin-bottom: 30px;
    font-size: 24px;
  }
}

.kv {
  position: relative;
  height: 100svh;
  min-height: 600px;
  max-height: 1000px;
}

.kv-shape {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  max-height: 800px;
}
.kv-shape img {
  width: 100%;
}

.kv-copy {
  font-weight: 500;
  font-size: 64px;
  letter-spacing: 0.135em;
}
@media screen and (max-width: 599px) {
  .kv-copy {
    font-size: 10.6844741235vw;
  }
}

.kv-content {
  padding-top: 300px;
}

.kv-news {
  margin-top: 120px;
}

.kv-news-top {
  display: flex;
  align-items: baseline;
  gap: 20px;
  margin-bottom: 16px;
}
.kv-news-top .en {
  font-size: 28px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .kv-news-top .en {
    font-size: 22px;
  }
}
.kv-news-top .jp {
  padding-bottom: 3px;
  font-size: 14px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .kv-news-top .jp {
    font-size: 11px;
  }
}

.kv-news-link {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 30px;
  max-width: 400px;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid color-mix(in srgb, #333 20%, transparent);
}
@media screen and (max-width: 599px) {
  .kv-news-link {
    gap: 8px 20px;
  }
}
.kv-news-link .text {
  letter-spacing: 0.08em;
}

.about {
  padding: 350px 0 100px;
}
@media screen and (max-width: 599px) {
  .about {
    padding: 200px 0 0;
  }
}

.about-content {
  display: flex;
  align-items: flex-end;
  gap: 60px;
}
@media screen and (max-width: 1023px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 599px) {
  .about-content {
    gap: 40px;
  }
}

.about-copy {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 599px) {
  .about-copy {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

.about-image {
  flex-shrink: 0;
  width: 480px;
  aspect-ratio: 3/2;
  margin-bottom: 20px;
  border-radius: 16px;
}
@media screen and (max-width: 599px) {
  .about-image {
    max-width: 480px;
    width: 100%;
  }
}
.about-image img {
  border-radius: 16px;
}

.service-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 60px 60px;
}
@media screen and (max-width: 1023px) {
  .service-content {
    gap: 60px 40px;
  }
}
@media screen and (max-width: 767px) {
  .service-content {
    justify-content: center;
  }
}
@media screen and (max-width: 599px) {
  .service-content {
    gap: 40px;
  }
}

.service-item {
  width: calc((100% - 60px) / 2);
}
@media screen and (max-width: 1023px) {
  .service-item {
    width: calc((100% - 40px) / 2);
  }
}
@media screen and (max-width: 767px) {
  .service-item {
    width: auto;
    max-width: 80%;
  }
}
@media screen and (max-width: 599px) {
  .service-item {
    max-width: 100%;
  }
}
.service-item:nth-child(2n) {
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .service-item:nth-child(2n) {
    margin-top: 0;
  }
}

.service-image {
  margin-bottom: 20px;
  border-radius: 16px;
}
.service-image img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  border-radius: 16px;
}

.service-num {
  position: relative;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .service-num {
    font-size: 24px;
  }
}
.service-num::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: -1;
  width: calc(100% - 48px);
  height: 1px;
  background-color: #DBDCDD;
}
@media screen and (max-width: 599px) {
  .service-num::after {
    width: calc(100% - 36px);
  }
}
.service-num .num {
  display: inline-block;
  padding-right: 5px;
}

.service-title {
  margin: 16px 0 12px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 599px) {
  .service-title {
    font-size: 24px;
  }
}

.news-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 60px 100px;
}
@media screen and (max-width: 1023px) {
  .news-content {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 599px) {
  .news-content {
    gap: 40px;
  }
}

.news-list {
  flex: 1;
}
@media screen and (max-width: 1023px) {
  .news-list {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .news-list {
    margin-top: -10px;
  }
}

.news-link {
  display: flex;
  gap: 10px 60px;
  padding: 20px;
  border-bottom: 1px solid #D9EBF3;
}
@media screen and (max-width: 599px) {
  .news-link {
    flex-direction: column;
    padding: 20px 0;
  }
}
.news-link .text {
  letter-spacing: 0.08em;
}

.company-content {
  padding: 60px 80px;
  border-radius: 16px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .company-content {
    padding: 60px 30px;
  }
}
@media screen and (max-width: 599px) {
  .company-content {
    padding: 30px 25px;
  }
}

.company-table {
  width: 100%;
  margin-top: -20px;
}
.company-table tr {
  border-bottom: 1px solid #D9EBF3;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .company-table tr th, .company-table tr td {
    display: block;
    font-size: 14px;
  }
}
.company-table tr th {
  padding: 20px;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  .company-table tr th {
    padding: 20px 0 0;
  }
}
.company-table tr td {
  padding: 20px;
}
@media screen and (max-width: 599px) {
  .company-table tr td {
    padding: 12px 0 20px;
  }
}

.lower-kv {
  padding-top: 200px;
}
@media screen and (max-width: 599px) {
  .lower-kv {
    padding-top: 140px;
  }
}

.lower-kv-title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 599px) {
  .lower-kv-title {
    font-size: 28px;
  }
}

.page-body {
  margin: 100px 0;
}
@media screen and (max-width: 599px) {
  .page-body {
    margin: 60px 0 0;
  }
}

.contact-lead {
  margin-bottom: 50px;
}
@media screen and (max-width: 599px) {
  .contact-lead {
    margin-bottom: 30px;
  }
}

.contact-table {
  width: 100%;
  margin-top: -25px;
}
@media screen and (max-width: 599px) {
  .contact-table {
    margin-top: -15px;
  }
}
.contact-table th, .contact-table td {
  letter-spacing: 0.04em;
}
@media screen and (max-width: 1023px) {
  .contact-table th, .contact-table td {
    display: block;
  }
}
@media screen and (max-width: 599px) {
  .contact-table th, .contact-table td {
    font-size: 14px;
  }
}
.contact-table th {
  width: 300px;
  padding: 26px 25px 25px 0;
  font-weight: 700;
  vertical-align: top;
}
@media screen and (max-width: 1023px) {
  .contact-table th {
    width: 100%;
    padding: 25px 0 0;
  }
}
@media screen and (max-width: 599px) {
  .contact-table th {
    padding: 15px 0 0;
  }
}
.contact-table th .text {
  display: inline-block;
  margin-top: 2px;
}
.contact-table th .required {
  float: right;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 30px;
  padding: 0px 4px;
  border-radius: 15px;
  background-color: #FF3D00;
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .contact-table th .required {
    width: 50px;
    height: 24px;
  }
}
.contact-table td {
  padding: 15px 0;
}
@media screen and (max-width: 599px) {
  .contact-table td {
    padding: 10px 0 5px;
  }
}
.contact-table td .confirm-data {
  display: block;
  height: auto;
  min-height: 45px;
  padding: 14px 20px;
  border-radius: 4px;
  background-color: #EBFBFF;
}
.contact-table td input,
.contact-table td textarea {
  padding: 15px 15px;
  border-radius: 4px;
  background-color: #EBFBFF;
  font-size: 16px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .contact-table td input,
.contact-table td textarea {
    padding: 12px 15px;
  }
}
.contact-table td input.full,
.contact-table td textarea.full {
  width: 100%;
}
.contact-table td input.error, .contact-table td textarea.error {
  border: 1px solid #e80303;
  background-color: #fce3e2;
}
.contact-table td label.error {
  display: block;
  position: relative;
  margin-top: 0.5em;
  color: #e80303;
  font-size: 0.9em;
}

.contact-privacy {
  margin-top: 30px;
}

.contact-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .contact-btn-wrap {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 40px;
  }
}
.contact-btn-wrap .btn.contact-btn {
  margin: 0;
}

.contact-error-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
  color: #e80303;
}
@media screen and (max-width: 599px) {
  .contact-error-list {
    font-size: 14px;
  }
}

a, button {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  a:hover, button:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover .arrow {
    right: 20px;
    transition: 0.3s;
  }
}

.wave {
  position: absolute;
  top: -190px;
  width: 100%;
  height: 200px;
}

#waveCanvas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.fluid-about {
  position: absolute;
  top: -350px;
  left: -700px;
  z-index: -1;
}
.fluid-about svg {
  width: 1400px;
  height: 1200px;
}
@media screen and (max-width: 599px) {
  .fluid-about {
    top: -180px;
    left: -240px;
  }
  .fluid-about svg {
    width: 600px;
    height: 500px;
  }
}

.fluid-service {
  position: absolute;
  top: 200px;
  right: -700px;
  z-index: -1;
}
.fluid-service svg {
  width: 1400px;
  height: 1200px;
}
@media screen and (max-width: 599px) {
  .fluid-service {
    top: -130px;
    right: -240px;
  }
  .fluid-service svg {
    width: 600px;
    height: 500px;
  }
}

.fluid-news {
  position: absolute;
  top: -200px;
  left: -250px;
  z-index: -1;
}
.fluid-news svg {
  width: 500px;
  height: 500px;
}
@media screen and (max-width: 599px) {
  .fluid-news {
    top: -150px;
    left: -180px;
  }
}

.fluid-company01 {
  position: absolute;
  top: 0;
  right: -300px;
  z-index: -1;
}
.fluid-company01 svg {
  width: 500px;
  height: 500px;
}
@media screen and (max-width: 599px) {
  .fluid-company01 {
    top: -100px;
    right: -200px;
  }
}

.fluid-company02 {
  position: absolute;
  bottom: -200px;
  left: -300px;
  z-index: -1;
}
.fluid-company02 svg {
  width: 500px;
  height: 500px;
}

.fadeInUp {
  opacity: 0;
}