@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// kv
.kv {
  position: relative;
  height: 100svh;
  min-height: 600px;
  max-height: 1000px;
}
.kv-shape {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  max-height: 800px;
  img {
    width: 100%;
  }
}
.kv-copy {
  font-weight: 500;
  font-size: 64px;
  letter-spacing: 0.135em;
  @include m.mq(max599) {
    font-size: calc((64/599)*100vw);
  }
}
.kv-content {
  padding-top: 300px;
}
.kv-news {
  margin-top: 120px;
}
.kv-news-top {
  display: flex;
  align-items: baseline;
  gap: 20px;
  margin-bottom: 16px;
  .en {
    font-size: 28px;
    letter-spacing: 0.04em;
    @include m.mq(max599) {
      font-size: 22px;
    }
  }
  .jp {
    padding-bottom: 3px;
    font-size: 14px;
    letter-spacing: 0.1em;
    @include m.mq(max599) {
      font-size: 11px;
    }
  }
}
.kv-news-link {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 30px;
  max-width: 400px;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid color-mix(in srgb, v.$black 20%, transparent);
  @include m.mq(max599) {
    gap: 8px 20px;
  }
  .text {
    letter-spacing: 0.08em;
  }
}


// about
.about {
  padding: 350px 0 100px;
  @include m.mq(max599) {
    padding: 200px 0 0;
  }
}
.about-content {
  display: flex;
  align-items: flex-end;
  gap: 60px;
  @include m.mq(max1023) {
    flex-direction: column;
    align-items: center;
  }
  @include m.mq(max599) {
    gap: 40px;
  }
}
.about-text {
}
.about-copy {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.12em;
  @include m.mq(max599) {
    margin-bottom: 20px;
    font-size: 28px;
  }
}
.about-image {
  flex-shrink: 0;
  width: 480px;
  aspect-ratio: 3/2;
  margin-bottom: 20px;
  border-radius: 16px;
  @include m.mq(max599) {
    max-width: 480px;
    width: 100%;
  }
  img {
    border-radius: 16px;
  }
}

// service
.service-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 60px 60px;
  @include m.mq(max1023) {
    gap: 60px 40px;
  }
  @include m.mq(max767) {
    justify-content: center;
  }
  @include m.mq(max599) {
    gap: 40px;
  }
}
.service-item {
  width: calc((100% - 60px)/2);
  @include m.mq(max1023) {
    width: calc((100% - 40px)/2);
  }
  @include m.mq(max767) {
    width: auto;
    max-width: 80%;
  }
  @include m.mq(max599) {
    max-width: 100%;
  }
  &:nth-child(2n) {
    margin-top: 80px;
    @include m.mq(max767) {
      margin-top: 0;
    }
  }
}
.service-image {
  margin-bottom: 20px;
  border-radius: 16px;
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    border-radius: 16px;
  }
}
.service-num {
  position: relative;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0.04em;
  @include m.mq(max599) {
    font-size: 24px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: -1;
    width: calc(100% - 48px);
    height: 1px;
    background-color: v.$border;
    @include m.mq(max599) {
      width: calc(100% - 36px);
    }
  }
  .num {
    display: inline-block;
    padding-right: 5px;
  }
}
.service-title {
  margin: 16px 0 12px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.12em;
  @include m.mq(max599) {
    font-size: 24px;
  }
}

// news
.news-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 60px 100px;
  @include m.mq(max1023) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include m.mq(max599) {
    gap: 40px;
  }
}
.news-list {
  flex: 1;
  @include m.mq(max1023) {
    width: 100%;
  }
  @include m.mq(max599) {
    margin-top: -10px;
  }
}
.news-link {
  display: flex;
  gap: 10px 60px;
  padding: 20px;
  border-bottom: 1px solid v.$border-blue;
  @include m.mq(max599) {
    flex-direction: column;
    padding: 20px 0;
  }
  .text {
    letter-spacing: 0.08em;
  }
}

// company
.company-content {
  padding: 60px 80px;
  border-radius: 16px;
  background-color: v.$white;
  @include m.mq(max767) {
    padding: 60px 30px;
  }
  @include m.mq(max599) {
    padding: 30px 25px;
  }
}
.company-table {
  width: 100%;
  margin-top: -20px;
  tr {
    border-bottom: 1px solid v.$border-blue;
    letter-spacing: 0.08em;
    th, td {
      @include m.mq(max599) {
        display: block;
        font-size: 14px;
      }
    }
    th {
      padding: 20px;
      font-weight: 500;
      @include m.mq(max599) {
        padding: 20px 0 0;
      }
    }
    td {
      padding: 20px;
      @include m.mq(max599) {
        padding: 12px 0 20px;
      }
    }
  }
}
